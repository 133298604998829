.logout {
    align-self: end;
    border-radius: 30px;
    background-color: #ffbbbb;
    color: #fff;
    display: grid;
    grid-area: top-half / picture / top-half-end / picture-end;
    height: 30px;
    justify-self: center;
    transition: background-color 0.1s linear;
    width: 30px;
    z-index: 1;
}

.logout svg {
    font-size: 16px;
    place-self: center;
}

.logout:hover {
    background-color: #fa0000;
}