.btn {
    background-color: #ff0000;
    border: 1px solid #d60000;
    border-radius: 2px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 12px 8px 12px;
    text-align: center;
    transition: background-color 0.1s linear;
}

.btn svg {
    padding-right: 5px;
}

.btn:hover {
    cursor: pointer;
    background-color: #d60000;
}
