.login {
    column-gap: 5px;
    display: grid;
    grid-area: header / user / header-end / user-end;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: inherit;
}

.login button:first-child {
    align-self: center;
    grid-area: header / 3 / header-end / 4;
    justify-self: right;
}

.login button:last-child {
    align-self: center;
    grid-area: header / 4 / header-end / 5;
    justify-self: left;
}
