.add_button {
  align-self: center;
  justify-self: left;
}

.resources {
  display: grid;
  grid-template-rows: 50px 1fr;
  padding: 20px;
  row-gap: 10px;
}

form {
  display: flex;
  align-items: flex-end;
}

.resources a {
    color: grey;
    text-decoration: none;
}

.resources a:hover {
    color: grey;
    text-decoration:underline;
    cursor:pointer;
}
