.downloads_container {
    display: grid;
    column-gap: 25px;
    row-gap: 25px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 125px auto;
    padding: 20px;
}

.downloads_container h2 {
    font-weight: 400;
    grid-column: 1;
    margin: 0;
}
