.release_license_info {
    grid-column: 2;
    grid-row: 2;
}

.release_license_info ul {
    grid-column: 1 / span 2;
}

.release_license_info ul {
    height: inherit;
}

.release_license_text {
    scrollbar-color: #ccc #fff;
    height: 250px;
    overflow-y: scroll;
    margin: 0;
}

.release_license_text::-webkit-scrollbar {
    width: auto;
}

.release_license_text::-webkit-scrollbar-track {
    background: #fff;
}

.release_license_text::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 3px solid #fff;
    height: 30px;
}

.release_license_info li svg {
    padding-right: 10px;
}