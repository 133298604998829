.release_container {
    column-gap: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 75px auto auto;
    padding: 20px;
    row-gap: 25px;
}

.release_files {
    grid-column: 1 / span 2;
    grid-row: 4;
}

.add_button {
    align-self: center;
    justify-self: left;
}

.release_customer {
    grid-column: 1 / span 3;
    grid-row: 3;
}

.release_customer_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px ;

}

.add_form {
    margin: 0 20px 0 0;
}

.add_cust {
    align-self: center;
    justify-self: left;
    margin-left: 10px;
}
