form {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

form button[type="reset"] {
    margin-right: 20px;
}

.download_releases {
    display: flex;
    padding: 20px 20px 20px 20px;
    row-gap: 10px;
}

.download_table {
    flex-grow: 1;
    padding-left: 20px;
}
