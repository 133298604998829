.total_downloads {
    grid-column: 2;
    justify-self: end;
}

.total_downloads_count {
    color: #82bc00;
    font-size: 75px;
    margin: 0;
    text-align: right;
}

.total_downloads_title {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}