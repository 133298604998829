.add_button {
    align-self: center;
    justify-self: left;
}

.content_spaces {
    display: grid;
    grid-template-rows: 50px 1fr;
    padding: 20px;
    row-gap: 10px;
}