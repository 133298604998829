.checksumButton {
    margin: 5px;
}

.table {
    background-color: #fff;
    overflow: hidden;
}

.table_body {
    color: #666;
    background-color: #fff;
    border: 1px solid #fff;
    font-size: 15px;
}

.table_row {
    color: #666;
    background-color: #fff;
}

.table_body th,
.table_body tr,
.table_body td {
    background-color: #fff;
}

.table_body th {
    font-size: 16px;
}

.table_head th {
    color: #333;
    font-size: 15px;
    font-weight: 400;
}

textarea {
    resize: none;
}

.table_style {
    padding-bottom: 0;
    padding-top: 0;
}

.copy_checksum {
    display: 'flex';
    align-items: 'center';
    padding-left: '5px';
}