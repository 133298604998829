.customer_page_container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.customer_page_details_container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.customer_page_details_container>div {
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px #eee;
    padding: 10px;
    height: 400px;
    display: flex;
    flex-direction: column;
}

.container_title_wrapper {
    height: 45px;
    display: flex;
}

.container_title_wrapper>h3 {
    background-image: linear-gradient(to right,
            #999 0,
            #999 3px,
            #fff 3px);
    color: #444;
    font-weight: 400;
    font-size: 14px;
    grid-column: 1;
    grid-row: 1;
    margin: auto 0 auto 16px;
    padding: 0 0 0 15px;
    -ms-flex-align: center;
}

.customer_info_container {
    margin-right: 10px;
}

.cusotmer_content_space_container {
    margin-left: 10px;
}

.cusotmer_content_space_container > ul {
    overflow-y: auto;
    flex: 1;
}

.customer_details {
    grid-column: 1;
    grid-row: 2;
}

.customer_page_details_container svg {
    padding-right: 10px;
}

.customers_content_spaces {
    grid-column: 2;
    grid-row: 2;
}


