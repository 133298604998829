.button {
    background-color: #fff;
    border: none;
    border-radius: 25px;
    color: #666;
    height: 45px;
    place-self: end end;
    overflow: hidden;
    width: 45px;
}

.button:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}
