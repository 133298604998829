.release_title {
    grid-column: 1;
    grid-row: 1;
}

.release_title p,
.release_title h2 {
    margin: 0;
}

.release_title h2 {
    font-size: 40px;
    font-weight: 400;
    padding-right: 10px;
}
