.app {
    color: #666;
    display: grid;
    font-family: 'Roboto', sans-serif;
    grid-template-columns: [sidebar] 220px [sidebar-end main-column] auto [main-column-end];
    grid-template-rows: [header] 80px [header-end main-row] auto [main-row-end];
    height: 100vh;
    width: 100vw;
}

.app_loading {
    display: grid;
    height: 100vh;
    margin: auto;
    width: 100vw;
}

.loading {
    display: grid;
    height: 100vh;
}

.loading div {
    place-self: center;
}

.cookie_font {
    background: #666;
}

.cookie_button {
    background-color: #82bc00;
    color: #f6f6f6;
    font-size: 14px;
    font-weight: bold;
}

.cookie_font .cookie_link {
    color: #f6f6f6;
    font-size: 14px;
    font-weight: bold;
}

body {
    background-color: #f6f6f6;
}

.CookieConsent {
    align-items: center !important;
    background: #666 !important;
}

.link_policy {
    padding: 5px;
    color: white !important;
    font-weight: bold;
}
table {
    color: #666;
}
