.add_button {
    align-self: center;
    justify-self: left;
}

.accounts {
    display: grid;
    grid-template-rows: 50px 1fr;
    padding: 20px;
    row-gap: 10px;
}

form {
    display: flex;
    align-items: flex-end;
}

button {
    margin: 1px;
}
