.btn {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #999;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 12px 8px 12px;
    text-align: center;
    transition: border 0.1s linear, color 0.1s linear;
}

.btn:hover {
    cursor: pointer;
    border: 1px solid #333;
    color: #333;
}
