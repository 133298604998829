.form_drawer {
    color: #666;
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 50vw;
}

.form_drawer button {
    align-self: flex-start;
}

.buttons_area {
    padding-top: 10px;
}

.form_drawer_title {
    background-image: linear-gradient(
        to right,
        #82bc00 0,
        #82bc00 5px,
        #fff 5px
    );
    font-weight: 400;
    padding-left: 15px;
}
