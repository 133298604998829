.card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px #eee;
    grid-template-columns: auto 50px;
    grid-template-rows: 45px auto;
    height: auto;
    padding: 10px;
}

.card h3 {
    background-image: linear-gradient(
            to right,
            #999 0,
            #999 3px,
            #fff 3px
    );
    color: #444;
    font-weight: 400;
    font-size: 14px;
    grid-column: 1;
    grid-row: 1;
    margin: auto 0 auto 16px;
    padding: 0 0 0 15px;
}

.card button:hover {
    color: #82bc00;
}
